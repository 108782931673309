import "whatwg-fetch";
import svg4everybody from "svg4everybody";
import AOS from "aos";
import inView from "in-view";
import animateNumber from "Modules/animateNumber.js";
import getMediaVersion from "Modules/getMediaVersion.js";
import getWindowScrollTop from "Modules/windowScrollTop.js";
import transformName from "Modules/transformName.js";
import numberWithSpaces from "Modules/numberWithSpaces.js";
import ButtonScrollTop from "Components/button-scroll-top/ButtonScrollTop.js";
import PopupCookies from "Components/popup-cookies/PopupCookies.js";
import PopupServices from "Components/popup-services/PopupServices.js";
import PopupCallback from "Components/popup-callback/PopupCallback.js";
import PopupTest from "Components/popup-test/PopupTest.js";
import ServicesSlider from "Components/services/ServicesSlider.js";
import AdvantagesSlider from "Components/advantages/AdvantagesSlider.js";
import PortfolioSlider from "Components/portfolio/PortfolioSlider.js";
import Subscription from "Components/subscription/Subscription.js";
import FeedbackForm from "Components/feedback-form/FeedbackForm.js";
import ContactsFeedbackForm from "Components/contacts-feedback-form/ContactsFeedbackForm.js";
import VacancyForm from "Components/vacancy-form/VacancyForm.js";
import ButtonVacancy from "Components/button-vacancy/ButtonVacancy.js";
import NewsList from "Components/news-list/NewsList.js";
// import Cloud from "Components/cloud/Cloud.js";



AOS.init({
    disable: "mobile",
    offset: 60,
    once: true
});

document.addEventListener("DOMContentLoaded", function() {

    const popupTestElement = document.querySelector(".js-popup-test");
    let popupTest;

    if (popupTestElement) {
        popupTest = new PopupTest(popupTestElement);
    }


    const buttonsTestPopup = document.querySelectorAll(".js-button-test-popup");

    for (let i = 0, len = buttonsTestPopup.length; i < len; i++) {
        initButtonTestPopup(buttonsTestPopup[i], popupTest);
    }


    const servicesElement = document.querySelector(".js-services");

    if (servicesElement) {
        new ServicesSlider(servicesElement);
    }


    const advantagesElement = document.querySelector(".js-advantages");

    if (advantagesElement) {
        new AdvantagesSlider(advantagesElement);
    }


    const portfolioElement = document.querySelector(".js-portfolio");

    if (portfolioElement) {
        new PortfolioSlider(portfolioElement);
    }


    const subscriptionElement = document.querySelector(".js-subscription");

    if (subscriptionElement) {
        new Subscription(subscriptionElement);
    }


    const feedbackFormElement = document.querySelector(".js-feedback-form");

    if (feedbackFormElement) {
        new FeedbackForm(feedbackFormElement);
    }


    const contactsFeedbackFormElement = document.querySelector(".js-contacts-feedback-form");

    if (contactsFeedbackFormElement) {
        new ContactsFeedbackForm(contactsFeedbackFormElement);
    }


    const newsListElement = document.querySelector(".js-news-list");

    if (newsListElement) {
        new NewsList(newsListElement);
    }


    const popupServicesElement = document.querySelector(".js-popup-services");
    let popupServices;

    if (popupServicesElement) {
        popupServices = new PopupServices(popupServicesElement);
    }


    const buttonsServicesPopup = document.querySelectorAll(".js-button-services-popup");

    for (let i = 0, len = buttonsServicesPopup.length; i < len; i++) {
        initButtonOpenPopup(buttonsServicesPopup[i], popupServices);
    }


    const popupCallbackElement = document.querySelector(".js-popup-callback");
    let popupCallback;

    if (popupCallbackElement) {
        popupCallback = new PopupCallback(popupCallbackElement);
    }


    const buttonsCallbackPopup = document.querySelectorAll(".js-button-callback-popup");

    for (let i = 0, len = buttonsCallbackPopup.length; i < len; i++) {
        initButtonOpenPopup(buttonsCallbackPopup[i], popupCallback);
    }


    const vacancyFormElement = document.querySelector(".js-vacancy-form");

    if (vacancyFormElement) {
        new VacancyForm(vacancyFormElement);
    }


    const buttonsVacancy = document.querySelectorAll(".js-button-vacancy");

    for (let i = 0, len = buttonsVacancy.length; i < len; i++) {
        initButtonVacancy(buttonsVacancy[i]);
    }


    const buttonScrollTopElement = document.querySelector(".js-button-scroll-top");

    if (buttonScrollTopElement) {
        new ButtonScrollTop(buttonScrollTopElement);
    }


    const servicesPreviewsButtons = document.querySelectorAll(".js-service-preview-button");

    for (let i = 0, len = servicesPreviewsButtons.length; i < len; i++) {
        initServicePreviewButton(servicesPreviewsButtons[i], popupServices);
    }


    const animatingNumbersElements = document.querySelectorAll(".js-animating-number");

    for (let i = 0, len = animatingNumbersElements.length; i < len; i++) {
        initAnimatingNumber(animatingNumbersElements[i], popupServices);
    }


    const popupCookiesElement = document.getElementById("popup-cookies");

    if (popupCookiesElement) {
        new PopupCookies(popupCookiesElement);
    }


    // const cloudElement = document.querySelector(".js-cloud");

    // if (cloudElement) {
    //     new Cloud(cloudElement);
    // }


    const servicePreviewMiniBlocks = document.querySelectorAll(".js-service-preview-mini");

    if (servicePreviewMiniBlocks.length) {
        const cloudBlock = document.querySelector(".js-cloud");
        const cloudImageBaseClass = cloudBlock.getAttribute("data-image-base-class");
        const cloudImageActiveClass = cloudImageBaseClass + "_active";

        for (let i = 0, len = servicePreviewMiniBlocks.length; i < len; i++) {
            initServicePreviewMini(servicePreviewMiniBlocks[i], cloudImageActiveClass);
        }
    }


    const parallaxBlocks = document.querySelectorAll(".js-parallax-block");

    for (let i = 0, len = parallaxBlocks.length; i < len; i++) {
        let parallaxRatio = 0.25;
        if (i === 0) {
            parallaxRatio = 0.17;
        }
        initParallaxBlock(parallaxBlocks[i], parallaxRatio);
    }


    svg4everybody();
});


function initButtonOpenPopup(button, popup) {
    button.addEventListener("click", function(e) {
        e.preventDefault();
        if (popup) {
            popup.open();
        }
    });
}


function initServicePreviewButton(button, popup) {
    const serviceId = button.getAttribute("data-service-id");

    button.addEventListener("click", function(e) {
        e.preventDefault();
        if (popup) {
            popup.open(serviceId);
        }
    });
}


function initButtonVacancy(button) {
    new ButtonVacancy(button);
}


function initAnimatingNumber(element) {
    inView.threshold(1);
    inView(".js-animating-number").once("enter", inViewCallback);

    function inViewCallback() {
        animateNumber(element, 9800, 10000, 2200, numberWithSpaces);
    }
}


function initServicePreviewMini(container, imageActiveClass) {
    const imageId = container.getAttribute("data-image-id");
    const imageElement = document.getElementById(imageId);

    container.addEventListener("mouseenter", function() {
        imageElement.className.baseVal += " " + imageActiveClass;
    });

    container.addEventListener("mouseleave", function() {
        const currentClass = imageElement.className.baseVal;
        const newClass = currentClass.replace(" " + imageActiveClass, "");
        imageElement.className.baseVal = newClass;
    });
}


function initButtonTestPopup(button, popup) {
    button.addEventListener("click", function(e) {
        e.preventDefault();
        if (popup) {
            popup.open();
        }
    });
}


function initParallaxBlock(element, parallaxRatio) {
    const elementStyle = element.style;
    let isActive = false;

    checkActivity();

    window.addEventListener("resize", function() {
        checkActivity();
    });


    function checkActivity() {
        const mediaVersion = getMediaVersion();

        if (mediaVersion == "desktop" && !isActive) {
            setElementPosition();
            window.addEventListener("scroll", containerScrollHandler);
            isActive = true;
        } else if (mediaVersion != "desktop" && isActive) {
            window.removeEventListener("scroll", containerScrollHandler);
            isActive = false;
        }
    }

    function setElementPosition(scrollTop, parallaxRatio) {
        const transformValue = scrollTop * parallaxRatio + "px";
        elementStyle[transformName] = "translateY(" + transformValue + ")";
    }

    function containerScrollHandler() {
        const windowScrollTop = getWindowScrollTop();
        setElementPosition(windowScrollTop, parallaxRatio);
    }
}


