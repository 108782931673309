import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import TweenLite from "gsap/TweenLite";
import CSSPlugin from "gsap/CSSPlugin";
const gsapPlugins = [CSSPlugin]; // eslint-disable-line no-unused-vars
import FeedbackForm from "Components/feedback-form/FeedbackForm.js";
import PopupServicesItemDetailed from "Components/popup-services/PopupServicesItemDetailed.js";

export default function PopupServices(container, openCallback, closeEndCallback) {
    const containerBaseClass = container.getAttribute("data-base-class");
    const containerActiveClass = containerBaseClass + "_active";
    const containerItemActiveClass = containerBaseClass + "_item-active";
    const itemBaseClass = container.getAttribute("data-item-base-class");
    const itemActiveClass = itemBaseClass + "_active";
    const closeButton = container.querySelector(".js-popup-close-button");
    const backButton = container.querySelector(".js-popup-services-back-button");
    const servicesButtons = container.querySelectorAll(".js-popup-services-item-button");
    const servicesBlocks = {};
    const servicesItemsDetailed = {};
    let activeServiceBlockId;
    const feedbackFormElement = container.querySelector(".js-popup-services-feedback-form");
    const feedbackForm = new FeedbackForm(feedbackFormElement);

    backButton.addEventListener("click", function() {
        container.classList.remove(containerItemActiveClass);
        if (activeServiceBlockId) {
            const activeServiceBlock = servicesBlocks[activeServiceBlockId];
            activeServiceBlock.classList.remove(itemActiveClass);
        }
        activeServiceBlockId = null;
        feedbackForm.reset();
    });

    for (let i = 0, len = servicesButtons.length; i < len; i++) {
        initServiceButton(servicesButtons[i]);
    }


    function initServiceButton(button) {
        const serviceId = button.getAttribute("data-service-id");
        const serviceBlock = document.getElementById(serviceId);
        servicesBlocks[serviceId] = serviceBlock;
        servicesItemsDetailed[serviceId] = new PopupServicesItemDetailed(serviceBlock, container);

        button.addEventListener("click", function(e) {
            e.preventDefault();
            activateService(serviceId);
        });
    }

    function activateService(serviceId) {
        container.scrollTop = 0;
        if (activeServiceBlockId) {
            const activeServiceBlock = servicesBlocks[activeServiceBlockId];
            const activeServiceItemDetailed = servicesItemsDetailed[activeServiceBlockId];
            activeServiceBlock.classList.remove(itemActiveClass);
            activeServiceItemDetailed.deactivate();
        }
        const activatingServiceBlock = servicesBlocks[serviceId];
        const activatingServiceItemDetailed = servicesItemsDetailed[serviceId];
        activatingServiceBlock.classList.add(itemActiveClass);
        activatingServiceItemDetailed.activate();
        activeServiceBlockId = serviceId;
        container.classList.add(containerItemActiveClass);
    }

    function containerClickHandler(e) {
        if (e.target == container) {
            close();
        }
    }

    function closeButtonClickHandler() {
        close();
    }

    function escapeClickHandler(e) {
        e = e || window.event;
        let isEscape = false;

        if ("key" in e) {
            isEscape = (e.key == "Escape" || e.key == "Esc");
        } else {
            isEscape = (e.keyCode == 27);
        }

        if (isEscape) {
            e.preventDefault();
            close();
        }
    }

    function openCompleteHandler() {
        closeButton.addEventListener("click", closeButtonClickHandler);
        container.addEventListener("click", containerClickHandler);
        document.addEventListener("keydown", escapeClickHandler);

        if (openCallback) {
            openCallback();
        }
    }

    function closeCompleteHandler() {
        container.scrollTop = 0;
        container.classList.remove(containerActiveClass);

        if (closeEndCallback) {
            closeEndCallback();
        }

        container.classList.remove(containerItemActiveClass);
        enableBodyScroll(container);

        if (activeServiceBlockId) {
            const activeServiceBlock = servicesBlocks[activeServiceBlockId];
            activeServiceBlock.classList.remove(itemActiveClass);
        }

        activeServiceBlockId = null;
        feedbackForm.reset();
    }

    function close() {
        closeButton.removeEventListener("click", closeButtonClickHandler);
        container.removeEventListener("click", containerClickHandler);
        document.removeEventListener("keydown", escapeClickHandler);
        if (activeServiceBlockId) {
            const activeServiceItemDetailed = servicesItemsDetailed[activeServiceBlockId];
            activeServiceItemDetailed.deactivate();
        }
        TweenLite.to(container, 0.4, { opacity: 0, onComplete: closeCompleteHandler });
    }

    this.open = function(serviceId) {
        disableBodyScroll(container);

        if (serviceId) {
            activateService(serviceId);
        }

        container.classList.add(containerActiveClass);
        TweenLite.to(container, 0.4, { opacity: 1, onComplete: openCompleteHandler });
    };
}