import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import TweenLite from "gsap/TweenLite";
import CSSPlugin from "gsap/CSSPlugin";
const gsapPlugins = [CSSPlugin]; // eslint-disable-line no-unused-vars
import CallbackForm from "Components/callback-form/CallbackForm.js";

export default function PopupCallback(container, openCallback, closeEndCallback) {
    const containerBaseClass = container.getAttribute("data-base-class");
    const containerActiveClass = containerBaseClass + "_active";

    const formElement = container.querySelector(".js-callback-form");
    const form = new CallbackForm(formElement);

    const closeButton = container.querySelector(".js-popup-close-button");


    function closeButtonClickHandler() {
        close();
    }

    function escapeClickHandler(e) {
        e = e || window.event;
        let isEscape = false;

        if ("key" in e) {
            isEscape = (e.key == "Escape" || e.key == "Esc");
        } else {
            isEscape = (e.keyCode == 27);
        }

        if (isEscape) {
            e.preventDefault();
            close();
        }
    }

    function openCompleteHandler() {
        closeButton.addEventListener("click", closeButtonClickHandler);
        document.addEventListener("keydown", escapeClickHandler);

        if (openCallback) {
            openCallback();
        }
    }

    function closeCompleteHandler() {
        container.classList.remove(containerActiveClass);
        form.reset();

        if (closeEndCallback) {
            closeEndCallback();
        }

        enableBodyScroll(container);
    }

    function close() {
        closeButton.removeEventListener("click", closeButtonClickHandler);
        document.removeEventListener("keydown", escapeClickHandler);
        TweenLite.to(container, 0.4, { opacity: 0, onComplete: closeCompleteHandler });
    }

    this.open = function() {
        disableBodyScroll(container);
        container.classList.add(containerActiveClass);
        TweenLite.to(container, 0.4, { opacity: 1, onComplete: openCompleteHandler });
    };
}