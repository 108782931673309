import smoothscroll from "smoothscroll-polyfill";
import getWindowScrollTop from "Modules/windowScrollTop.js";

smoothscroll.polyfill();

export default function ButtonScrollTop(button) {
    const baseClass = button.getAttribute("data-base-class");
    const visibleClass = baseClass + "_visible";
    let isAvailable = false;
    let isVisible = false;

    button.addEventListener("click", function(e) {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    });

    window.addEventListener("load", checkAvailability);
    window.addEventListener("resize", checkAvailability);


    function checkAvailability() {
        const documentScrollHeight = document.documentElement.scrollHeight;

        if (documentScrollHeight > (document.documentElement.clientHeight + 180) && !isAvailable) {
            checkVisibility();
            window.addEventListener("scroll", windowScrollHandler);
            isAvailable = true;
        } else if (documentScrollHeight <= (document.documentElement.clientHeight + 180) && isAvailable) {
            button.classList.remove(visibleClass);
            window.removeEventListener("scroll", windowScrollHandler);
            isAvailable = false;
        }
    }

    function checkVisibility() {
        const scrollTop = getWindowScrollTop();

        if ( scrollTop > 180 && !isVisible ) {
            button.classList.add(visibleClass);
            isVisible = true;
        } else if ( scrollTop <= 180 && isVisible ) {
            button.classList.remove(visibleClass);
            isVisible = false;
        }
    }

    function windowScrollHandler() {
        checkVisibility();
    }
}


