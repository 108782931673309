import Swiper from "swiper";

export default function AdvantagesSlider(container) {
    let slider;
    let isSliderActive = false;
    let resizeTimeout = 0;
    let sliderOptions = {
        speed: 500,
        slidesPerView: 1,
        slideClass: "js-advantages-item",
        wrapperClass: "js-advantages-wrapper"
    };

    if (window.matchMedia("(max-width: 1200px)").matches) {
        initSlider();
        isSliderActive = true;
    }

    window.addEventListener("resize", resizeHandler);


    function initSlider() {
        slider = new Swiper(container, sliderOptions);
    }

    function resizeHandler() {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(function() {
            if (window.matchMedia("(max-width: 1200px)").matches && !isSliderActive) {
                initSlider();
                isSliderActive = true;
            } else if (window.matchMedia("(min-width: 1201px)").matches && isSliderActive) {
                slider.destroy();
                isSliderActive = false;
            }
        }, 100);
    }

    this.update = function() {
        if (isSliderActive && slider) {
            slider.update();
        }
    };
}