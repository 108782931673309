function calculateWindowScrollTop() {
    const scrollTopValue = document.documentElement.scrollTop || document.body.scrollTop;
    return scrollTopValue;
}

let windowScrollTop = calculateWindowScrollTop();

window.addEventListener("load", function() {
    windowScrollTop = calculateWindowScrollTop();
});

window.addEventListener("resize", function() {
    windowScrollTop = calculateWindowScrollTop();
});

window.addEventListener("scroll", function() {
    windowScrollTop = calculateWindowScrollTop();
});

function getWindowScrollTop() {
    return windowScrollTop;
}

export default getWindowScrollTop;


