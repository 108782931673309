let transformName;

const transformNames = "transform WebkitTransform MozTransform OTransform msTransform".split(" ");

const elem = document.createElement("div");

for (let i = 0; i < transformNames.length; i++) {
    if (elem && elem.style[transformNames[i]] !== undefined) {
        transformName =  transformNames[i];
        break;
    }
}

export default transformName;