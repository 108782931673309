import getOffsetRect from "Modules/getOffsetRect.js";
import getWindowHeight from "Modules/windowHeight.js";
import getWindowScrollTop from "Modules/windowScrollTop.js";

export default function NewsList(container) {
    const itemsBlock = container.querySelector(".js-news-list-items");
    let loadButton;
    let loadButtonBaseClass;
    let loadButtonOffsetTop = 0;
    let windowHeight = 0;
    let windowScrollTop = 0;
    let loadTimeout = 0;

    initLoadButton();

    window.addEventListener("load", function() {
        if (loadButton) {
            calculateDimensions();
            window.addEventListener("resize", windowResizeHandler);
            window.addEventListener("scroll", windowScrollHandler);
        }
    });


    function initLoadButton() {
        loadButton = container.querySelector(".js-news-list-load-link");

        if (!loadButtonBaseClass) {
            loadButtonBaseClass = loadButton.getAttribute("data-base-class");
        }

        loadButton.addEventListener("click", function(e) {
            e.preventDefault();
        });
    }

    function calculateDimensions() {
        loadButtonOffsetTop = getOffsetRect(loadButton).top;
        windowHeight = getWindowHeight();
        windowScrollTop = getWindowScrollTop();
    }

    function checkLoading() {
        if ( (windowHeight + windowScrollTop) > (loadButtonOffsetTop + 80) ) {
            if (!loadTimeout) {
                loadTimeout = setTimeout(load, 400);
            }
        } else {
            clearTimeout(loadTimeout);
        }
    }

    function load() {
        window.removeEventListener("scroll", windowScrollHandler);
        window.removeEventListener("resize", windowResizeHandler);
        loadTimeout = 0;
        loadButton.classList.add(loadButtonBaseClass + "_active");
        const url = loadButton.href;

        fetch(url, {
            method: "GET"
        }).then(function(response) {
            if (response.ok) {
                return response.json();
            }

            throw new Error("Network response was not ok.");
        }).then(function(jsonData) {
            handleLoadRequest(jsonData);
        });
    }

    function handleLoadRequest(data) {
        if (data.items) {
            const itemsContainer = document.createElement("div");
            itemsContainer.innerHTML = data.items;
            const items = itemsContainer.children;
            addItems(items);
        }

        if (data.button) {
            deleteButton(loadButton);
            const buttonContainer = document.createElement("div");
            buttonContainer.innerHTML = data.button;
            const button = buttonContainer.firstChild;
            addButton(button);
            calculateDimensions();
            window.addEventListener("resize", windowResizeHandler);
            window.addEventListener("scroll", windowScrollHandler);
        }
    }

    function addItems(items) {
        for (let i = 0, len = items.length; i < len; i++) {
            itemsBlock.appendChild(items[0]);
        }
    }

    function deleteButton(button) {
        container.removeChild(button);
    }

    function addButton(buttonNew) {
        container.appendChild(buttonNew);
        initLoadButton();
        loadButtonOffsetTop = getOffsetRect(loadButton).top;
    }

    function windowResizeHandler() {
        calculateDimensions();
    }

    function windowScrollHandler() {
        windowScrollTop = getWindowScrollTop();
        checkLoading();
    }
}