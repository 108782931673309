export default function PopupCookies(container) {
    const closeButton = container.querySelector(".js-popup-cookies-close-button");
    closeButton.addEventListener("click", close);


    function close() {
        container.parentNode.removeChild(container);
    }
}


