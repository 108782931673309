import InputValidator from "Modules/InputValidator.js";

export default function CallbackForm(formElement) {
    formElement.setAttribute("novalidate", true);
    const url = formElement.action;
    const formBaseClass = formElement.getAttribute("data-base-class");
    const formActiveClass = formBaseClass + "_active";
    const inputsValidating = formElement.querySelectorAll(".js-contacts-feedback-form-text-input");
    const inputsValidators = [];
    const inputPhone = formElement.querySelector(".js-contacts-feedback-form-input-tel");
    const inputPhoneValidator = initTextInputValidator(inputPhone, {
        "isRequired": true,
        "isMask": true
    });
    inputsValidators.push(inputPhoneValidator);

    for (let i = 0, len = inputsValidating.length; i < len; i++) {
        const textInput = inputsValidating[i];
        const inputValidator = initTextInputValidator(textInput, {
            "isRequired": true
        });
        inputsValidators.push(inputValidator);
    }

    formElement.addEventListener("submit", function(e) {
        e.preventDefault();
        const isValid = validateForm();

        if (isValid) {
            const data = new FormData(formElement);
            data.append("ajax", "Y");

            fetch(url, {
                method: "POST",
                body: data
            }).then(function(response) {
                if (response.ok) {
                    return response.blob();
                }

                throw new Error("Network response was not ok.");
            }).then(function() {
                formElement.classList.add(formActiveClass);
            });
        }
    });


    function initTextInputValidator(textInput, options) {
        options = options || {};
        const isRequired = options.isRequired || textInput.required;
        const textInputInvalidClass = options.invalidClass || textInput.getAttribute("data-invalid-class");

        const textInputValidator = new InputValidator({
            "domElement": textInput,
            "requiredFlag": isRequired,
            "errorClass": textInputInvalidClass,
            "isMask": options.isMask
        });

        return textInputValidator;
    }

    function validateTextInputs(inputsValidators) {
        let isValid = true;

        for (let i = 0, len = inputsValidators.length; i < len; i++) {
            const isFormElementValid = inputsValidators[i].validate();
            if (!isFormElementValid) {
                isValid = false;
            }
        }

        return isValid;
    }

    function validateForm() {
        let isValid = true;

        const isTextInputsValid = validateTextInputs(inputsValidators);

        if (!isTextInputsValid) {
            isValid = false;
        }

        return isValid;
    }

    this.reset = function() {
        formElement.classList.remove(formActiveClass);
        for (let i = 0, len = inputsValidators.length; i < len; i++) {
            inputsValidators[i].reset();
        }
    };
}