function calculateWindowHeight() {
    const heightValue = document.documentElement.clientHeight;
    return heightValue;
}

let windowHeight = calculateWindowHeight();

window.addEventListener("load", function() {
    windowHeight = calculateWindowHeight();
});

window.addEventListener("resize", function() {
    windowHeight = calculateWindowHeight();
});

window.addEventListener("scroll", function() {
    windowHeight = calculateWindowHeight();
});

function getWindowHeight() {
    return windowHeight;
}

export default getWindowHeight;


