export default function InputFiles(container) {
    // const blockBaseClass = container.getAttribute("data-bem-class");
    // let isBlockActive = container.classList.contains(blockBaseClass + "_active");
    const fileTemplate = container.querySelector(".js-input-file-template");
    const fileInput = fileTemplate.querySelector(".js-input-file-input");
    const fileTemplateBaseClass = fileTemplate.getAttribute("data-base-class");
    // const addButtonSelector = fileTemplate.getAttribute("data-button-selector");
    // const addButton = document.querySelector(addButtonSelector);
    // const addButtonBaseClass = addButton.getAttribute("data-base-class");

    fileInput.addEventListener("change", function() {
        var files = fileInput.files;
        if (files && files[0]) {
            addFile(files[0]);
        }
    });

    function initDeleteButton(button, fileBlock) {
        button.addEventListener("click", function() {
            fileBlock.outerHTML = "";
        });
    }

    function addFile(file) {
        // var fileSize = file.size;
        // if ((fileSize / 1024 / 1024) <= 10) {
            // if (fileSize < 1024) {
            //     fileSize = Math.round(fileSize) + " " + fileMeasures.byte;
            // } else {
            //     fileSize = fileSize / 1024;
            //     if (fileSize < 1024) {
            //         fileSize = Math.round(fileSize) + " " + fileMeasures.kilobyte;
            //     } else {
            //         fileSize = ((Math.round(fileSize * 10) / 10) / 1024) + " " + fileMeasures.megabyte;
            //     }
            // }
            const fileName = file.name;
            const newFileBlock = fileTemplate.cloneNode(true);
            // container.insertBefore(newFileBlock, fileTemplate.nextSibling);
            container.insertBefore(newFileBlock, container.firstChild);
            fileInput.value = "";
            newFileBlock.querySelector(".js-input-file-input").removeAttribute("id");
            newFileBlock.querySelector(".js-input-file-name").innerText = fileName;
            const deleteButton = newFileBlock.querySelector(".js-input-file-delete-button");

            if (deleteButton) {
                initDeleteButton(deleteButton, newFileBlock);
            }

            newFileBlock.classList.add(fileTemplateBaseClass + "_active");

            // if (!isBlockActive) {
            //     container.classList.add(blockBaseClass + "_active");
            //     isBlockActive = true;
            // }
        // }
    }
}