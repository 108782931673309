import Swiper from "swiper";

export default function ServicesSlider(container) {
    const nextButtonSelector = container.getAttribute("data-slider-button-next-selector");
    const nextButtonElement = document.querySelector(nextButtonSelector);
    // const previousButtonSelector = container.getAttribute("data-slider-button-prev-selector");
    // const previousButtonElement = document.querySelector(previousButtonSelector);
    let slider;
    // let resizeTimeout = 0;
    const sliderOptions = {
        loop: true,
        slidesPerView: "auto",
        speed: 500,
        slideClass: "js-services-item",
        wrapperClass: "js-services-wrapper",
        navigation: {
            nextEl: nextButtonElement
        }
    };

    initSlider();

    // window.addEventListener("resize", resizeHandler);


    function initSlider() {
        slider = new Swiper(container, sliderOptions);
    }

    window.addEventListener("load", function() {
        if (slider) {
            slider.update();
        }
    });

    // function resizeHandler() {
    //     clearTimeout(resizeTimeout);
    //     resizeTimeout = setTimeout(function() {
    //         if (window.matchMedia("(max-width: 1200px)").matches) {
    //             slider.destroy();
    //         } else if (window.matchMedia("(min-width: 1201px)").matches) {
    //         }
    //     }, 100);
    // }

    this.update = function() {
        if (slider) {
            slider.update();
        }
    };
}