import noUiSlider from "nouislider";

export default function InputSlider(container, changeHandler) {
    const rangeSliderClass = container.getAttribute("data-range-slider-class");
    const inputLower = container.querySelector(".js-input-slider-input-lower");
    const inputElementBaseClass = inputLower.getAttribute("data-base-class");
    const minValue = parseInt(container.getAttribute("data-min-value"), 10);
    const maxValue = parseInt(container.getAttribute("data-max-value"), 10);
    const step = parseInt(container.getAttribute("data-step"), 10) || 1;
    const inputLowerValue = parseInt(inputLower.getAttribute("value"), 10);
    const sliderCurrentValueMin = inputLowerValue || minValue;

    const rangeSlider = document.createElement("div");
    rangeSlider.className = rangeSliderClass;
    container.appendChild(rangeSlider);

    const sliderController = noUiSlider.create(rangeSlider, {
        behaviour: "tap",
        start: [sliderCurrentValueMin],
        step: step,
        connect: true,
        range: {
            "min": minValue,
            "max": maxValue
        }
    });

    sliderController.on("update", function ( values, handle ) {
        const value = values[handle];
        const valueRounded = Math.round(value);

        if ( !handle ) {
            let inputNewValue = valueRounded;

            if (inputNewValue === 0) {
                inputNewValue = "";
            }

            inputLower.value = inputNewValue;

            // if (inputNewValue.length > 0) {
            //     inputLower.classList.add(inputElementBaseClass + "_filled");
            // } else {
            //     inputLower.classList.remove(inputElementBaseClass + "_filled");
            // }
        }
    });

    sliderController.on("set", function () {
        if (changeHandler) {
            changeHandler();
        }
    });

    inputLower.addEventListener("input", function() {
        if (this.value.length > 0) {
            this.classList.add(inputElementBaseClass + "_filled");
        } else {
            this.classList.remove(inputElementBaseClass + "_filled");
        }
    });

    inputLower.addEventListener("change", function() {
        const newValue = this.value;
        sliderController.set([newValue, null]);
    });

    container.className += " is-inited";


    this.reset = function() {
        // sliderController.reset();
        sliderController.set([sliderCurrentValueMin]);
    };

    this.defaultReset = function() {
        sliderController.set([minValue, maxValue]);
    };

}