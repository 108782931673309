// import getOffsetRect from "Modules/getOffsetRect.js";
// import getWindowHeight from "Modules/windowHeight.js";
import transformName from "Modules/transformName.js";

export default function PopupServicesItemDetailed(container, scrollContainer) {
    scrollContainer = scrollContainer || window;
    const backgroundIcon = container.querySelector(".js-service-detailed-background-icon");
    const PARALLAX_RATIO = 0.2;
    const backgroundIconStyle = backgroundIcon.style;


    function containerScrollHandler() {
        const scrollContainerScrollTop = scrollContainer.scrollTop;
        const transformValue = scrollContainerScrollTop * PARALLAX_RATIO + "px";
        backgroundIconStyle[transformName] = "translateY(" + transformValue + ")";
    }

    this.activate = function() {
        scrollContainer.addEventListener("scroll", containerScrollHandler);
    };

    this.deactivate = function() {
        scrollContainer.removeEventListener("scroll", containerScrollHandler);
        backgroundIconStyle[transformName] = "";
    };
}