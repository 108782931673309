let mediaVersion = defineMediaVersion();

window.addEventListener("load", function() {
    mediaVersion = defineMediaVersion();
});

window.addEventListener("resize", function() {
    mediaVersion = defineMediaVersion();
});

window.addEventListener("scroll", function() {
    mediaVersion = defineMediaVersion();
});

function getMediaVersion() {
    return mediaVersion;
}

function defineMediaVersion() {
    let mediaVersion;
    if (window.matchMedia("(max-width: 1200px)").matches) {
        mediaVersion = "mobile";
    } else {
        mediaVersion = "desktop";
    }
    return mediaVersion;
}

export default getMediaVersion;


