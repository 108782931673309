import maskInput from "vanilla-text-mask";

export default function InputValidator(options) {
    const self = this;
    self.element = options.domElement;
    self.errorClass = options.errorClass || "is-invalid";
    self._isMask = options.isMask;
    self.isValid = true;
    self.tag = self.element.tagName.toLowerCase();
    self.isTyped = "";
    const baseClass = self.element.getAttribute("data-base-class");
    self._filledClass = baseClass + "_filled";
    self._validClass = baseClass + "_valid";
    self.isFilled = true;
    const phoneMask = ["+", " ", "7", " ", "(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/];

    self.isRequired = options.requiredFlag;

    if (typeof self.isRequired === "undefined") {
        self.isRequired = self.element.required;
    }

    if (self.tag == "input") {
        self.type = self.element.type;
        if (self.type == "text" || self.type == "email" || self.type == "tel" || self.type == "number" || self.type == "password" || self.type == "search" || self.type == "url") {
            self.category = "text-input";
        }
    } else if (self.tag == "textarea") {
        self.category = "text-input";
    } else {
        self.type = "";
    }

    if (self._isMask) {
        maskInput({
            inputElement: self.element,
            mask: phoneMask
        });
    }

    if (self.category == "text-input") {
        self.minlength = self.element.getAttribute("minlength");

        if (self.minlength == "undefined" || self.minlength === null) {
            if (self._isMask) {
                self.minlength = 11;
            } else {
                self.minlength = 0;
            }
        } else {
            self.minlength = parseInt(self.minlength, 10);
        }

        self.isFilled = self.element.value.length > 0;

        if (self.isFilled) {
            self.element.classList.add(self._filledClass);
        } else {
            self.element.classList.remove(self._filledClass);
        }

        self.element.addEventListener("input", function() {
            self.isTyped = "typed";
            self.isFilled = this.value.length > 0;

            if (self.isFilled) {
                self.element.classList.add(self._filledClass);
            } else {
                self.element.classList.remove(self._filledClass);

                if (!self.isValid) {
                    self.setValid();
                }
            }
        });

        ["change", "blur"].forEach(function(e) {
            self.element.addEventListener(e, function() {
                /*if (self.isTyped == "typed") {
                    self.isTyped = true;
                    // self.isValid = undefined;
                }*/
            });
        });

        ["change", "blur", "input"].forEach(function(e) {
            self.element.addEventListener(e, function() {
                if (self.isTyped == "typed" && self.isFilled) {
                    self.validate();
                }
            });
        });
    } else if (self.type == "checkbox") {
        self.element.addEventListener("change", function() {
            if (!self.isValid) {
                self.validate();
            }
        });
    }

    if (options.isParentError) {
        self.errorElement = self.element.parentElement;
    } else {
        const errorBlockSelector = self.element.getAttribute("data-error-selector");
        self.errorElement = errorBlockSelector ? document.querySelector(errorBlockSelector) : this.element;
    }
}

InputValidator.prototype.setInvalid = function() {
    this.isValid = false;
    this.errorElement.classList.add(this.errorClass);
};

InputValidator.prototype.setValid = function() {
    this.isValid = true;
    this.errorElement.classList.remove(this.errorClass);
};

InputValidator.prototype.validate = function() {
    const self = this;

    if (this.isRequired && this.minlength < 1) {
        this.minlength = 1;
    }

    if (this.category == "text-input") {
        this.value = this.element.value;
        if (self._isMask) {
            const unmaskedValue = this.value.replace(/\D+/g, "");
            this.value = unmaskedValue;
        }
        this.valueLength = this.value.replace(/\s+/g, "").length;
        if (this.valueLength < this.minlength && this.isValid) {
            this.setInvalid();
        } else if (this.valueLength >= this.minlength && !this.isValid) {
            this.setValid();
        }
        if (this.type == "email" && this.isValid) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const isEmailValid = re.test(String(self.value).toLowerCase());
            if (!isEmailValid) {
                this.setInvalid();
            }
        }
    } else if ( (this.type == "checkbox" || this.type == "radio") && this.isRequired) {
        if (!this.element.checked && this.isValid) {
            this.setInvalid();
        } else if (this.element.checked && !this.isValid) {
            this.setValid();
        }
    }

    self.isTyped === false;

    return this.isValid;
};

InputValidator.prototype.reset = function() {
    if (this.category == "text-input") {
        this.element.value = "";
    }

    if (!this.isValid) {
        this.setValid();
    }

    if (this.isFilled) {
        this.element.classList.remove(this._filledClass);
        this.isFilled = false;
    }
};