import PopupVacancy from "Components/popup-vacancy/PopupVacancy.js";

export default function ButtonVacancy(button) {
    const url = button.href;
    let isVacancyLoading = false;
    let isVacancyLoaded = false;
    let popupVacancy;

    button.addEventListener("click", function(e) {
        e.preventDefault();
        if (!isVacancyLoaded && !isVacancyLoading) {
            makeLoadRequest();
        } else if (isVacancyLoaded) {
            openVacancy();
        }
    });

    function makeLoadRequest() {
        isVacancyLoading = true;

        fetch(url, {
            method: "GET"
        }).then(function(response) {
            if (response.ok) {
                return response.json();
            }

            throw new Error("Network response was not ok.");
        }).then(function(jsonData) {
            handleLoadRequest(jsonData);
        });
    }

    function handleLoadRequest(jsonData) {
        if (jsonData.content) {
            const vacancyBlockContainer = document.createElement("div");
            vacancyBlockContainer.innerHTML = jsonData.content;
            const vacancyBlock = vacancyBlockContainer.querySelector(".js-popup-vacancy");
            document.body.appendChild(vacancyBlock);
            initVacancy(vacancyBlock);
            openVacancy();
        }
    }

    function initVacancy(vacancyBlock) {
        popupVacancy = new PopupVacancy(vacancyBlock);
        isVacancyLoaded = true;
        isVacancyLoading = false;
    }

    function openVacancy() {
        if (popupVacancy) {
            popupVacancy.open();
        }
    }
}